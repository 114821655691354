import { onMounted, onUnmounted, ref } from 'vue'
import take from 'lodash.take'

export function useFakeLazyLoad(items: any[], count = 5) {

    const loadedItems = ref(take(items, count))

    onMounted(() => onScroll)
    window.addEventListener('scroll', onScroll, false)
    onUnmounted(() => window.removeEventListener('scroll', onScroll, false))

    function onScroll() {

        const scrollPercent = window.scrollY / (document.body.offsetHeight - window.innerHeight)
        const scrollPercentRounded = Math.round(scrollPercent * 100)

        if (scrollPercentRounded === 70) {

            loadedItems.value = take(items, loadedItems.value.length + count)

        }

    }

    return {
        loadedItems
    }

}
