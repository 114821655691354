import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Container = _resolveComponent("Container")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        _createVNode(_component_PageHeader, {
          class: "pt-32 lg:pt-64",
          header: _ctx.cms.page_header_heading,
          "sub-header": _ctx.cms.page_header_content,
          "header-span": 5
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Container, {
          fluid: "",
          class: "mt-48 space-y-24"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadedItems, (blog, index) => {
              return (_openBlock(), _createBlock(_component_Grid, { key: index }, {
                default: _withCtx(() => [
                  _createVNode(_component_AOS, {
                    type: "fade-up",
                    easing: "ease",
                    duration: 500,
                    delay: index * 400
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        span: [ 2, 4 ],
                        push: [ 0, 1 ]
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Anchor, {
                            route: { name: _ctx.Route.BlogPost, params: { slug: blog.slug } }
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Heading, {
                                tag: "h2",
                                "no-margin": "",
                                innerHTML: blog.title
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 2
                          }, 1032, ["route"]),
                          _createVNode(_component_Paragraph, { class: "pt-2" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(blog.updated_at) + " · Written by " + _toDisplayString(blog.author.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      (blog.cover)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            span: [ 2, 6 ],
                            push: [ 0, 0 ],
                            class: "mt-12"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Anchor, {
                                route: { name: _ctx.Route.BlogPost, params: { slug: blog.slug } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Picture, {
                                    image: blog.cover,
                                    auto: ""
                                  }, null, 8, ["image"])
                                ]),
                                _: 2
                              }, 1032, ["route"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Column, {
                        span: [ 2, 4 ],
                        push: [ 0, 1 ],
                        class: "mt-24"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Paragraph, {
                            innerHTML: blog.introduction
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["delay"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.cms.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.cms.seo,
          "structured-data": _ctx.cms.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}