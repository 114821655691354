

    import { defineComponent } from 'vue'
    import Container from '../components/Container.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Anchor from '../components/Anchor.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Picture from '../components/Picture.vue'
    import Heading from '../components/Heading.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import Paragraph from '../components/Paragraph.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { Route } from '../enums/Route'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import { useFakeLazyLoad } from '../compositions/behaviors/UseFakeLazyLoad'

    export default defineComponent({
        name: 'Blog',
        components: {
            Container,
            Column,
            Grid,
            Anchor,
            PageHeader,
            Picture,
            Heading,
            Paragraph,
            MetaInfo,
            ThemeChanger
        },
        setup() {

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            const { blog, blogs } = useCms()
            const { loadedItems } = useFakeLazyLoad(blogs.value)

            return {
                Route,
                cms: blog,
                blogs,
                loadedItems
            }

        }
    })

